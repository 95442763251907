@tailwind base;
@tailwind components;
@tailwind utilities;



/** FONTS **/
@font-face {
    font-family: MaziusItalic;
    src: url("/fonts/mazius-italic.otf");
}
@font-face {
    font-family: Gilroy;
    src: url("/fonts/Gilroy-Regular.ttf");
}

:root{
    --primary-black: #242323;
    --primary-white: #FFFFFF;
    --special-white: #F4F1F0;
    --primary-red: #DD4211;
    --clear-red: #EA6446;
    --black: #000000;
    --grey: #D3D3D3;
    --dark-grey: #7C7B7B;
    --clear-black: #504F4F;
    --clear-grey: #2F2F2F;
    --dark-black: #A7A7A7;
}


body{
    background-color: var(--primary-black);
}
body, .smooth-container { scroll-behavior: smooth }
span{
    font-family: MaziusItalic, sans-serif;
}
p, h1, h2, h3, h4, h5, h6{
    color: var(--primary-white);
    font-family: Epilogue;
}
.btn-arrow{
    position: relative;
    transition: .5s;
    &:hover{
        font-weight: bold;
        transition: .5s;
    }
}
.btn-arrow:after{
    content: url('../img/arrow-focus.svg');
    margin-left: 12px;
    position: absolute;
    top: 0px;
    right: -50px;
}
.btn-arrow:hover:after{
    transition: .5s;
    zoom: 1.3;
    right: -56px;
    top: -3px;
}
.btn-redarrow:after{
    content: url('../img/arrow-red-focus.svg');
    margin-left: 12px;
    position: absolute;
    top: -4px;
    right: -50px;
}
.btn-redarrow:hover:after{
    transition: .5s;
    zoom: 1.3;
    right: -56px;
    top: -7px;
}
.btn-blackarrow{
    &:hover{
        font-weight: bold;
    }
}
.btn-blackarrow:after{
    content: url('../img/arrow-black-focus.svg');
    margin-left: 12px;
    position: relative;
    top: 6px;
}
.bg-dark{
    background-color: #212121;
}
.lock{
    overflow: hidden;
}
/** LOADER **/
.loader-ville{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-red);
    display: flex;
    justify-content: center;
    align-items: center;
}
/** HOME **/
#header{
    display: flex;

}
.header{
    z-index: 10000;
}
.menu-mobile{
    display: none;
}
.showreel-modal {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(36,35,35,0.75);
    display: none;

    &.open {
        display: block;
    }

    &-content {
        background-color: transparent;
        margin: auto;
        border: none;
        width: 70%;
        position: relative;
        top: 55%;
        transform: translateY(-50%);
        img{
            width: 100%;
        }
    }

    &-close {
        position: absolute;
        top: 0px;
        right: -40px;
        font-size: 20px;
        font-weight: bold;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 10000;
    }
}
.navigation{
    a{
        font-family: 'Gilroy';
        color: var(--primary-white);
    }
    a:last-child{
        position: relative;
        font-size: 22px;
        color: var(--primary-red);
    }
}

.ct-header{
    height:100%;
}
.video-bg{
    filter: brightness(0.5);
}
.container-home-header{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    hn{
        font-family: "Gilroy", sans-serif;
        color: var(--primary-white);
    }
    a{
        font-family: "Gilroy";
        font-weight: 500;
        color: var(--primary-white);
        cursor: pointer;
    }
}
.container-btn-video{
    display: inline;
    .btn-video{
        display: inline;
        font-family: Gilroy;
        cursor: pointer;
    }
    .arrow-down{
        &:after{
            content: url("../img/bottom_arrow.svg");
            position: relative;
            left: 8px;
            top: -1px;
        }
    }
    .arrow-up{
        &:after{
            content: url("../img/arrow-up.svg");
            position: relative;
            left: 8px;
            top: -1px;
            transform: rotate(180deg);
        }
    }
    .mobile-menu{
        position: absolute;
        top: 110px;
        left: 0;
        background-color: var(--primary-white);
        width: 100vw;
        padding: 33px 0;
        li{
            width: 60%;
            margin: 0 auto;
            padding: 7px 0;
            a{
                color: var(--primary-black);
                font-size: 26px;
                &:hover{
                    font-weight: 700;
                }
            }
        }
    }
}
.container-home-agence{
    .title-agence{
        h2{
        span{
            color: var(--primary-red);
            font-family: Epilogue;
        }
        }
    }
    .text-agence{
        p{
            font-family: "Gilroy", sans-serif;
        }
        a{
            font-family: "Gilroy", sans-serif;
            color: var(--primary-white);
        }
    }
}

/** PhotoVideo **/
@keyframes animate-in {
    0% {
        color:var(--primary-white);
    }
    100% {
        color:var(--primary-red);
    }
}
@keyframes animate-out {
    0% {
        color:var(--primary-red);
    }
    100% {
        color:var(--primary-white);
    }
}
.container-photovideo{
    cursor: url("../img/cursor-hover.svg"), auto;
    .photo{
        h2{
            font-size: 188px;
            font-family: Epilogue, sans-serif;
            font-weight: 900;
            overflow: hidden;
            background: linear-gradient(to right, var(--clear-red), var(--clear-red) 50%,  white 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 500ms ease;
            text-decoration: none;
            a{
                cursor: url("../img/hover_cursor.svg") 0 0, auto;
            }
        }
        h2:hover {
            background-position: 0 100%;
        }
        ul{
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            position: relative;
            top: -50px;
            li{
                color: var(--primary-white);
                font-family: "Gilroy", sans-serif;
                padding: 4px;
                .link_menu{
                    &:hover{
                        font-weight: 700;
                        color: var(--clear-red);
                    }
                }
            }
        }
    }
    .video{
        h2{
            font-size: 188px;
            font-family: Epilogue, sans-serif;
            font-weight: 900;
            overflow: hidden;
            background: linear-gradient(to right, var(--clear-red), var(--clear-red) 50%,  white 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 500ms ease;
            text-decoration: none;
            a{
                cursor: url("../img/hover_cursor.svg") 0 0, auto;

            }

        }
        h2:hover {
            background-position: 0 100%;
        }
        ul{
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            position: relative;
            top: -50px;
            li{
                color: var(--primary-white);
                font-family: "Gilroy", sans-serif;
                padding: 4px;
                .link_menu{
                    &:hover{
                        font-weight: 700;
                        color: var(--clear-red);
                    }
                }

            }
        }
    }
}

/** SLIDER CLIENTS HOME **/
.slider{
    background-color: var(--clear-red);
    overflow: hidden;
    h2{
        span{
            font-family: Epilogue;
            color: var(--primary-red);
        }
    }
    &-left{
        .mask{
            display: flex;
            transform: translateX(-40%);
            img{
                width: 200px;
                height: 200px;
                object-fit: contain;
                margin-left: 24px;
            }
        }
    }
    &-right{
      .mask{
          display: flex;
          justify-content: end;
          transform: translateX(40%);
          img{
              width: 200px;
              height: 200px;
              object-fit: contain;
              margin-right: 24px;
          }
      }
    }
}
.slider-mobile{
    display: none;
}
.iframe-projets{
    width: 960px;
    height: 540px;
    display: block;
    margin: 0 auto;
}
.cominsoon{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
        text-align: center;
        font-size: 6rem;
        font-weight: 700;
    }
}
/** PROJETS HOME **/
.container-projetshome{
    h2{
        font-family: Epilogue;
        span{
            color: var(--primary-red);
        }
    }
    .container-projects{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        .card-projet{
            cursor: pointer;
            img{
                filter: brightness(0.7);
                border-radius: 5px;
                width: 1000px;
                height: 540px;
                object-fit: cover;
            }
        }
    }
    .link-real{
        font-family: "Gilroy", sans-serif;
        font-size: 25px;
        color: var(--primary-white);
       margin: 0 50px 16px 0;
       margin: 0 50px 16px 0;
    }
    .modal{
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(36, 35, 35, 0.75);
        display: none;
        &-content{
            background-color: transparent;
            margin: auto;
            border: none;
            width: 70%;
            position: relative;
            top: 55%;
            transform: translateY(-50%);
        }
        &-close{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }
    .modal.open{
        display: block;
    }
}

.container-footer{
    background-color: var(--primary-white);
    /* A CHANGER PAR DES CLASSES TAILWIND */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    h4{
        font-family: "Gilroy", sans-serif;
        font-weight: 600;
        color: var(--black);
    }
    ul{
        li{
            font-family: "Gilroy", sans-serif;
            padding: 8px 0;
           &:hover{
               .icon-footer{
                   transition: .5s;
                   transform:rotate(20deg);
               }
           }
            .icon-footer{
                color: var(--primary-red);
            }
            a{
                &:hover{
                    color: var(--primary-red);
                    font-weight: 600;
                    text-decoration: underline;
                }

            }
        }
    }
}

/** AVIS CLIENTS **/
.container-avis{
    display: flex;
    &-title{
        width: 30%;
    font-family: Epilogue, sans-serif;
        span{
            color: var(--primary-red);
            font-family: Epilogue, sans-serif;
            font-size: 40px;
        }
    }
    &-blocs{
        width: 70%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 32px;
    }
    .container-avis-blocs{
        overflow: scroll;
        cursor: grab;
        &::-webkit-scrollbar{
            display: none;
        }
        .card-avis{
            width: 500px;
            border-radius: 5px;
            .star{
                display: flex;
            }
            p{
                font-family: "Gilroy";
            }
            .person{
                font-weight: 600;
            }
        }
        .card-avis:nth-child(2n){
            background-color: var(--clear-black);
        }
        .card-avis:nth-child(2n+1){
            background-color: var(--primary-red);
        }
    }
}
/** SUBFOOTER **/
.subfooter{
    background-color: var(--primary-white);
;
    &-made{
        font-family: "Gilroy", sans-serif;
        width: 200px;
        p{
            color: var(--black);
            font-weight: 700;
        }
        li{
            list-style: none;
        }
    }
    &-line-logo{
        width: 100%;
        .line{
            background-color: #ADADAD;
            width: 100%;
            height: 1px;
        }

        .logo{
            img{
                width: 100px;
            }
        }
    }
}
/** FORM HOME **/

.formhome{
    background-color: var(--grey);

    &-title{
        position: relative;
        z-index: 1000;
        .round-bg{
            z-index: -1;
            position: absolute;
            top: -20px;
            left: 150px;
            width: 300px;
            height: 300px;
            background-color: var(--primary-white);
            border-radius: 200px;
        }
    }
    &-left-content{
        color: var(--primary-red);
        font-weight: bold;
    }
    &-text{
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        color: var(--dark-grey);
}
    &-form{
        input{
            width: 100%;
            margin-bottom: 24px;
            padding: 12px;
            background-color: transparent;
            border-bottom: var(--clear-black) 1px solid;
            &::placeholder{
                color: var(--dark-grey);
                font-family: "Gilroy", sans-serif;
            }
        }
        textarea{
            width: 100%;
            background-color: transparent;
            border-bottom: var(--clear-black) 1px solid;
            padding: 8px;
            &::placeholder{
                color: var(--dark-grey);
                font-family: "Gilroy", sans-serif;
            }
        }
        input[type='file']{
            padding:12px;
            border: none;
    }
        button{
            float: right;

        }
}
}


/** PAGE AGENCE **/

/** DESCRIPTIF AGENCE **/
.descriptif{
    display: grid;
    grid-template-columns: 65% 35%;
    &-txt {
        &-1{
            font-family: Epilogue, sans-serif;
            font-size: 18px;
            width: 60%;
            h1{
                font-weight:700;
                font-size: 49px;
                span{
                    font-family: Epilogue, sans-serif;
                    color: var(--primary-red);
                }
            }
            p{
                font-family: "Gilroy", sans-serif;
            }
        }
        &-2{
            font-family: Epilogue, sans-serif;
            font-size: 18px;
            width: 60%;
            position: relative;
            h2{
                font-weight:700;
                font-size: 49px;
                span{
                    font-family: Epilogue, sans-serif;
                    color: var(--primary-red);
                }
            }
            p{
                font-family: "Gilroy", sans-serif;
            }
            .round-bg{
                position: absolute;
                z-index: -1;
                top: -30px;
                right: 0;
                width: 300px;
                height: 300px;
                background-color: #2F2F2F;
                border-radius: 250px;
            }
        }
    }
    &-img{
        img{
            width: 100%;
            object-fit: cover;
            height: 110%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}

/** CHIFFRES AGENCE **/
.chiffres{
    background-color: var(--clear-red);

    h2{
        font-family: Epilogue, sans-serif;
    }
    &-chiffre{
        p{
            font-family: Gilroy, sans-serif;
            text-align: center;
            &:first-child{
                font-size: 72px;
                font-family: MaziusItalic, sans-serif;
                text-align: center;
            }
        }
    }
}

/** BACKSTAGES AGENCE **/

.backstages{
    &-title{
        font-family: Epilogue, sans-serif;
        span{
            color: var(--primary-red);
            font-family: Epilogue, sans-serif;
        }
    }
    &-grid{
        .red-background{
            background-color: var(--primary-red);
            p{
                font-family: MaziusItalic, sans-serif;
                font-size: 44px;
            }
            &-link{
                color: var(--primary-white);
            }
        }
        .backstages-img{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}



/** PAGE PHOTOGRAPHIE **/
.ct-btt{
    background-color: var(--primary-red);
    .icon-btt{
        padding: 12px 11px;
        width: 50px;
    }
}
.menuphoto{
    &-1{
        position: relative;
        a:hover{
            img{
                filter: opacity(100);
                transition: .5s;
            }
            p{
                font-size: 48px;
                transition: .5s;
            }
        }
            img{
                filter: opacity(16%);
                width: 100%;

            }
        &-title {
            font-family: Epilogue, sans-serif;
            font-style: italic;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        &-link{
            font-family: Gilroy, sans-serif;
            color: var(--primary-white);
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        }

    &-2{
        position: relative;
        a:hover{
            img{
                filter: opacity(100);
                transition: .5s;
            }
            p{
                font-size: 48px;
                transition: .5s;
            }
        }
        img{
            filter: opacity(16%);
            width: 100%;
        }
        &-title {
            font-family: Epilogue, sans-serif;
            font-style: italic;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        &-link{
            font-family: Gilroy, sans-serif;
            color: var(--primary-white);
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }

    &-3{
        position: relative;
        a:hover{
            img{
                filter: opacity(100);
                transition: .5s;
            }
            p{
                font-size: 48px;
                transition: .5s;
            }
        }
        img{
            filter: opacity(16%);
            width: 100%;
        }
        &-title {
            font-family: Epilogue, sans-serif;
            font-style: italic;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        &-link{
            font-family: Gilroy, sans-serif;
            color: var(--primary-white);
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
}

/** BLOC PORTRAITS **/

.portraits {
    &-title {
        font-family: Epilogue, sans-serif;

        span {
            font-family: Epilogue, sans-serif;
            color: var(--primary-red);
        }
    }

    &-text {
        font-family: Gilroy, sans-serif;
    }

    .red-background {
        background-color: var(--primary-red);
        height: 436px;

        p {
            font-family: MaziusItalic, sans-serif;
            font-size: 44px;
            text-align: left;
        }

        &-link {
            .see-less{
                font-family: 'Gilroy';
                background-color: var(--primary-black);
                padding: 6px 14px;
                color: var(--primary-white);
                border-radius: 5px;
                &:after{
                    content: url("../img/arrow-down.svg");
                    position: relative;
                    top: 7px;
                    left: 5px;
                }
            }
            .see-more{
                font-family: 'Gilroy';
                background-color: var(--primary-black);
                padding: 6px 14px;
                color: var(--primary-white);
                border-radius: 5px;
                &:after{
                    content: url("../img/arrow-right.svg");
                    position: relative;
                    top: 7px;
                    left: 5Px;
                }
            }
            &-link {
                color: var(--primary-white);
                background-color: var(--primary-black);
                padding: 0 8px;
            }
        }
    }
    &-container-img{
        img{
            width: 100%;
            height: 436px;
        }
    }
}


.fade-animation {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.fade-in {
    animation-name: fadeIn;
}
.fade-out {
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/** PAGE REALISATIONS **/
.reals {
    .images-listing{
        border: none;
    }
    &-title {
        font-family: Epilogue, sans-serif;
    }
    ul{
        font-family: 'Gilroy';
        color: white;
        border-bottom: 1px solid var(--clear-black);
        li{
            cursor: pointer;
        }
        .active{
            border-bottom: 3px solid var(--primary-red);
            padding: 0 0 16px 0;
        }
    }
    &-content{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        row-gap: 100px;
        div{
            cursor: pointer;
        }
        .real{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
        }
        /*.real .img:first-child{
            grid-row-start: 1;
            grid-column-start: 1;

            grid-row-end: 3;
            grid-column-end: 3;
        }
        .real .img:nth-child(2){
            grid-row-start: 1;
            grid-column-start: 3;

            grid-row-end: 2;
            grid-column-end: 4;
        }
        .real .img:nth-child(3){
            grid-row-start: 2;
            grid-column-start: 3;

            grid-row-end: 3;
            grid-column-end: 4;
        }*/
        div{
            h2{
                font-family: 'Inter', sans-serif;
                font-weight: 700;
                font-size: 24px;
            }
            p{
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: var(--grey);
            }
        }
       /* div:first-child{
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 3;
            grid-column-end: 4;
        }*/
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: opacity(16%);
            transition-duration: .5s;
            transition-property: filter;
            &:hover{
                filter: opacity(100%);
            }
        }
    }
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(36,35,35,0.75);
        display: none;

        &.open {
            display: block;
        }

        &-content {
            background-color: transparent;
            margin: auto;
            border: none;
            width: 70%;
            position: relative;
            top: 55%;
            transform: translateY(-50%);
            img{
                width: 100%;
            }
        }

        &-close {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }

}
.videos{
    .publicitaires{
        display: flex;
        flex-direction: column;
        justify-content: end;
        .title-vdo{
            font-size: 54px;
        }
        h1{
            font-weight: 700;
        }
        p{
            font-family: 'Gilroy';
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background-color: var(--dark-grey);
        margin-bottom: 24px;
    }
    .lesplus{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .leplus-matos{
            position: relative;
            h3{
                font-weight: 700;
                font-size: 30px;
                line-height: 1.2;
            }
            p{
                margin-top: 21px;
            }
        }
        .leplus-matos:before{
            content: url('../img/video.svg');

            position: absolute;
            left: -39px;
            z-index: 1;
            top: -14%;
        }
        .leplus-plus{
            position: relative;
            h3{
                font-weight: 700;
                font-size: 30px;
                line-height: 1.2;
            }
            p{
                margin-top: 21px;
            }
        }
        .leplus-plus:before{
            content: url('../img/mixage.svg');
            position: absolute;
            left: -40px;
            z-index: 1;
            top: -12%;
        }
        .leplus-bdx{
            position: relative;
            h3{
                font-weight: 700;
                font-size: 30px;
                line-height: 1.2;
                span{
                    display: block;
                    font-family: Epilogue, sans-serif;
                }
            }
            p{
                margin-top: 21px;
            }
        }
        .leplus-bdx:before{
            content: url('../img/localisation.svg');
            position: absolute;
            left: -33px;
            z-index: 1;
            top: -16%;
        }
    }
}

/** COMPOSANTS ETAPES FULL **/

.etapes-scroll-container{
    width: 900px;
    display: flex;
    overflow: scroll;
    .slide-content{
        width: 500px;
    }
}

.etapes{
    background-color: var(--clear-grey);
    h2{
        span{
            color: var(--primary-red);
            font-size: 40px;
            font-family: Epilogue, sans-serif;
        }
    }
    &-cardcontainer{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        overflow: scroll;
        gap: 39px;
        padding: 100px 0 0 0;
        &::-webkit-scrollbar{
            display: none;
        }
        .card{
            background-color: var(--primary-white);
            color: var(--primary-black);
            width: 400px;
            height: 315px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 28px 16px 28px;
            span{
                color: var(--primary-red);
                font-size: 70px;
            }
            p{
                font-family:Gilroy, sans-serif ;
                font-weight: 500;
                color: var(--primary-black);
            }
            h3{
                color: var(--primary-black);
                font-family: Epilogue;
                font-size: 24px;
                font-weight: 700;
            }
        }
        .card:nth-child(2n){
            transform: translateY(-70px);
        }
        .card:nth-child(3n){
            transform: translateY(-30px);
        }
    }
}


/** PAGE VIDEOS **/
.active{
    font-weight: bold;
}

.autres {
    h2 {
        span {
            color: var(--primary-red);
            font-size: 40px;
            font-family: Epilogue, sans-serif;
        }
    }

    &-videos {
        display: flex;
        justify-content: space-between;

        &-api {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 65%;
            grid-column-gap: 30px;

            .card {
                height: 325px;

                img {
                    height: 275px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }

                h3 {
                    font-size: 18px;
                }
            }
        }
        .modal{
            position: fixed;
            z-index: 10000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(36, 35, 35, 0.75);
            display: none;
            &-content{
                background-color: transparent;
                margin: auto;
                border: none;
                width: 70%;
                position: relative;
                top: 55%;
                transform: translateY(-50%);
            }
            &-close{
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 20px;
                font-weight: bold;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
        .modal.open{
            display: block;
        }

        &-bloc {
            width: 35%;
            height: 275px;
            margin-left: 30px;
            background-color: var(--primary-red);
            border-radius: 5px;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            p {
                font-family: 'MaziusItalic';
                font-size: 40px;
            }

            a {
                align-self: end;
                font-family: Gilroy, sans-serif;
                color: var(--primary-white);
                font-size: 20px;
                margin-right: 50px;
                position: relative;
            }

        a{
            &:after {
                content: url('../img/send-after-white.svg');
                position: absolute;
                right: -50px;
            }
            &:hover{
                &:after{
                    transition: .5s;
                    right: -56px;
                    zoom: 1.3;
                    top: -5px;
                }
            }
        }
        }
    }
}
.menuphoto-1-link, .menuphoto-2-link, .menuphoto-3-link{
    &:after{
        content: url('../img/arrow-right-portraits.svg');
        position: absolute;
        right: -50px;
    }
    &:hover{
        &:after{
            transition: .5s;
            zoom: 1.5;
            top: -10px;
        }
    }
}
.backstages-grid{
    .scd-sec{
        img{
            height: 400px;
            object-fit: cover;
        }
        div{
            &:first-child{
                margin-right: 2rem;
            }
        }
    }
    img{
        border-radius: 5px;
    }
    .red-background{
        border-radius: 5px;
    }
}
/** RESPONSIVE **/

@media (max-width: 500px) {
    .etapes-cardcontainer {
        .card{
            width: 310px;
            height: auto;
        }
    }
    .cominsoon h1{
        font-size: 4rem;
    }
    .lesplus {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 30px;
    }
    .autres-videos{
        flex-wrap: wrap;
        &-api{
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
        }
        &-bloc{
            width: 100%;
            margin-left: 0;
        }
    }
    .ct-backstage-l1{
        flex-direction: column-reverse !important;
    }
    .video-bg{
        height: 40vh;
    }
    .iframe-projets{
        width: 395px !important;
        height: 210px !important;
    }
    #header{
        display: none;
    }
    .menu-mobile{
        background-color: var(--primary-black);
        display: inherit;
        position: relative;
        z-index: 100000;
        &-header{
            padding: 24px 16px;
        }
        .navigation-mobile{
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: 1100;
            top: 0;
            background-color: var(--special-white);
            &-header{
                padding: 24px 16px;
            }
            .navigation-mobile-links{
                a{
                    font-family: 'Gilroy', sans-serif;
                    font-size: 32px;
                    padding: 16px 0;
                }
                p{
                    font-family: 'Gilroy', sans-serif;
                    font-size: 32px;
                    padding: 16px 0;
                    color: var(--black);
                }
            }
        }
    }
    .container-home-header{
        background-size: cover;
        height: 60vh;
    }
    .container-photovideo{
        .photo{
            h2{
                font-size: 115px;
            }
            ul{
                li{
                    padding: 8px;
                }
            }
        }
        .video{
            h2{
                font-size: 115px;
            }
            ul{
                li{
                    padding: 8px;
                }
            }
        }
    }
    .container-projetshome{
        .link-real{
            display: none;
        }
        .container-projects{
            grid-template-columns: repeat(1, 1fr);
            .card-projet{
                cursor: pointer;
                img{
                    height: 250px;
                }
            }
        }
        .modal-content{
                background-color: transparent;
                margin: 0 auto;
                border: none;
                width: 93%;
                position: relative;
                top: 55%;
                transform: translateY(-50%);
        }
    }
    .container-avis{
        flex-direction: column;
        &-title{
            width: 100%;
            h2{
                font-size: 36px;
                padding-bottom: 36px;
            }
        }
        .container-avis-blocs{
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            .card-avis{
                width: 90%;
                margin: 0 auto;
            }
        }
    }
    .formhome-text{
        font-size: 15px;
    }
    .container-footer{
        grid-template-columns: repeat(1, 1fr);
    }
    .infos-boite{
        margin-bottom: 24px;
    }
    .menu-footer{
        margin-bottom: 24px;
    }
    .line{
        display: none;
    }
    .title-vdo{
        font-size: 40px !important;
    }
    .subfooter-made{
        width: 50%;
        height: 88px;
    }
    .subfooter-line-logo{
        width: 50%;
    }
    .descriptif{
        grid-template-columns: repeat(1, 1fr);
        &-txt-1{
            width: 100%;
        }
        &-txt-2{
            margin: 24px 0;
            width: 100%;
        }
        &-img{
            img{
                height: 100%;
            }
        }
    }
    .backstages-grid {
        .red-background{
            border-radius: 5Px;
            p{
                font-size: 24px;
            }
        }

        .red-background-link{
            font-size: 12px;
            font-family: "Gilroy", sans-serif;
        }
    }
    .menuphoto{
        .menuphoto-1{
            height: 222px;
            img{
                height: 222px;
                object-fit: cover;
                object-position: 10% 24%;
                filter: opacity(50%);
            }
            &-link{
                top: 67%;
            }
        }
        .menuphoto-2{
            height: 222px;
            img{
                height: 222px;
                object-fit: cover;
                object-position: 10% 50%;
                filter: opacity(50%);
            }
            &-link{
                top: 67%;
            }
        }
        .menuphoto-3{
            height: 222px;
            img{
                height: 222px;
                object-fit: cover;
                object-position: 10% 24%;
                filter: opacity(50%);
            }
            &-link{
                top: 67%;
            }
        }
    }
    .portraits{
        .red-background{
            border-radius: 5px;
            p{
                font-size: 28px;
                margin-bottom: 48px;
            }
        }
    }
    .MuiImageList-masonry{
        column-count: 1 !important;
    }
    .container-btn-video {
        .mobile-menu{
            position: relative;
            top: inherit;
            left: inherit;
            background-color:inherit;
            padding: inherit;
            li{
                width: 100%;
                margin: 0 2rem;
                list-style: none;
            }
        }
    }
    .reals{
        ul{
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            li{
                margin-right: 24px;
                white-space: nowrap;
            }
        }
    }
    .reals-content{
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 80px !important;
        div{
            height: 260px;
            &:first-child{
                grid-row-start: inherit;
                grid-column-start: inherit;
                grid-row-end: inherit;
                grid-column-end: inherit;
            }
        }
    }
    .reals-content {
        div{
            height: inherit;
            cursor: pointer;
        }
        .real{
            grid-template-columns: repeat(1, 1fr);
            .img{
                &:nth-child(2){
                    grid-row-start: inherit;
                    grid-column-start: inherit;
                    grid-row-end: inherit;
                    grid-column-end: inherit;
                }
                &:first-child{
                    grid-row-start: inherit;
                    grid-column-start: inherit;
                    grid-row-end: inherit;
                    grid-column-end: inherit;
                }
                &:nth-child(3){
                    grid-row-start: inherit;
                    grid-column-start: inherit;
                    grid-row-end: inherit;
                    grid-column-end: inherit;
                }
            }
        }

    }
    .slider{
        display: none;
    }
    .slider-mobile{
        display: block;
        background-color: var(--clear-red);
        .slider-content{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 20px;
            width: 90%;
            margin: auto;
            .slide-m{
                img{
                    background-color: rgba(244, 241, 240, 0.12);
                    padding: 8px;
                    width: 85px;
                    height: 85px;
                    object-fit: contain;
                }
            }
        }
    }
    .publicitaires{
        &:nth-child(2){
            p{
                margin-top: 16px;
            }
        }
    }
}
/** VOYAGES **/
.images{
    .reals-content{
        display: initial;
    }
}
/** MENTIONS LEGALES **/
.mentionslegales{
    h1{
        font-weight: 700;
        font-size: 54px;
        margin: 24px 0 50px 0;
        text-align: center;
    }
    h4{
       font-weight: 700;
        margin-top: 16px;
        font-size: 20px;
    }
    p{
        a{
            color: var(--primary-red);
        }
    }
}
/** REDIRECT MAIL **/
.redirect-mail{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-family: 'Gilroy';
    color: white;
    p{
        margin-bottom: 16px;
    }
}
/** FORM **/
.btnform{
    text-align: left;
    border-bottom: none !important;
    font-family: "Gilroy", sans-serif;
    cursor: pointer;
}
.maintenance{
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        font-size: 60px;
        font-weight: 700;
    }
    a{
        text-decoration: underline;
    }
}
@media (max-width: 700px){
    .maintenance{
        h1{
            font-size: 30px;
        }
    }
}
@media (max-width: 425px){
    .reals {
        .modal-content{
            width: 80%;
        }
    }
    .iframe-projets{
        width: 345px !important;
    }
}
@media (max-width: 325px){
    .showreel-modal-close{
        top: -20px;
        right: 20px;
    }
    .reals {
        .modal-content{
            width: 100%;
        }
    }
    .autres-videos {
        .modal-content{
            width: 100%;
            top: 35%;
        }
    }
    .iframe-projets{
        width: 300px !important;
    }
    .title-vdo{
        font-size: 30px !important;
    }
    .container-photovideo .video h2, .container-photovideo .photo h2{
        font-size: 76px;
        margin-bottom: 16px;
    }
}